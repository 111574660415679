import React from 'react'
import styled from 'styled-components'

export const justForLooks = true

const Wrapper = styled.div`
  padding: 50px;
  color: #99a5ab;
  border: 3px dashed #99a5ab;
  background: #e4e5e9;
  font-size: 30px;
  text-align: center;

  &::after {
    content: 'Drop Files Here';
  }
`

export const Edit = () => <Wrapper />
