import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  color: #fff;
  text-align: center;
  padding: 15px 20px;
  background: #e05554;
  border-radius: 5px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
`
const Header = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`

export default ({ title, type = 'error', children, ...rest }) => (
  <Wrapper {...rest} type={type}>
    <Header>{title}</Header>
    {children}
  </Wrapper>
)
