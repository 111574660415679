import { Link, Location } from '@reach/router'
import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  position: relative;
  margin: 0 auto;
  width: ${props => props.length * 120}px;
`

const Item = styled(Link)`
  padding: 12px;
  background: #e4e5e9;
  border-right: 1px solid #c5c7cc;
  color: black;
  width: 120px;
  text-align: center;
  text-decoration: none;
  ${props =>
    props.active &&
    css`
      position: relative;
      background: #eaa93f;
      color: #fff;
    `}

  ${props =>
    props.active &&
    props.showArrow &&
    css`
      &::after {
        content: '';
        width: 15px;
        height: 15px;
        transform: rotate(45deg);
        background: #eaa93f;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
        position: absolute;
        left: calc(50% - 8px);
        top: calc(100% - 7px);
        z-index: -1;
      }
    `}
`

export default ({ options, ...rest }) => (
  <Location>
    {({ location }) => (
      <Wrapper primary={false} length={options.length} {...rest}>
        {options.map(option => {
          const active = option.exact
            ? location.pathname === option.href
            : location.pathname.startsWith(option.href)
          const showArrow = option.arrow ? option.arrow(location) : false
          return (
            <Item
              to={option.href}
              key={option.id}
              active={active}
              showArrow={showArrow}
            >
              {option.label}
            </Item>
          )
        })}
      </Wrapper>
    )}
  </Location>
)
