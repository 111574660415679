import { cloneDeep, get, mapValues, set, isEmpty } from 'lodash'
import React, { useState } from 'react'

import Gadgets from '../gadgets'
import DefaultAssembler from '../pages/forms-id/configure/default-assembler'

const createField = (Gadget, type, onChange, _value) => props => {
  const { formKey, label, children, ...config } = props
  const initialValue = Gadget.initialValue ? Gadget.initialValue() : undefined
  const initialConfig = Gadget.initialConfig ? Gadget.initialConfig() : {}
  const [value, setValue] = useState(get(_value, formKey) || initialValue)
  if (Gadget.justForLooks) Gadget = Gadgets.Generic
  const Assembler = Gadget.Assembler || DefaultAssembler
  return (
    <Assembler
      label={label}
      // description={description}
      component={
        <Gadget.Edit
          type={type}
          config={isEmpty(config) ? initialConfig : config}
          onChange={val => {
            setValue(val)
            onChange(formKey, val)
          }}
          value={value}
          children={children}
        />
      }
    />
  )
}

const Wrap = ({ label, children }) => (
  <DefaultAssembler
    label={label}
    // description={description}
    component={children}
  />
)

export default class Formy extends React.Component {
  constructor (props) {
    super(props)
    const value = cloneDeep(props.value)
    const onChange = (key, newValue) => {
      set(value, key, newValue)
      props.onChange(cloneDeep(value))
    }
    this.fields = mapValues(Gadgets, (field, type) =>
      createField(field, type, onChange, value)
    )
    this.fields.Wrap = Wrap
  }

  render () {
    return this.props.children(this.fields)
  }
}
