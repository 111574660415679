import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding: 10px;
`

const Label = styled.label`
  display: block;
  font-weight: bold;
  font-size: 13px;
  margin-left: 20px;
  color: #545f66;
  margin-bottom: 2px;
`

export default ({ label, description, component }) => (
  <Container>
    {label && <Label>{label}</Label>}
    {component}
  </Container>
)
