const key = 'lyzer'

export function load () {
  try {
    const raw = localStorage.getItem(key)
    return JSON.parse(raw)
  } catch {
    return null
  }
}

export function save (config) {
  try {
    localStorage.setItem(key, JSON.stringify(config))
  } catch {}
}
