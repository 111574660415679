import React from 'react'
import styled from 'styled-components'

const StyledSelect = styled.select`
  width: 100%;
  border-radius: 3px;
  padding: 9px 10px;
  border: 1px solid #99a5ab;
`

export const Option = styled.option``

export const Select = React.forwardRef(({ onChange, ...rest }, ref) => (
  <StyledSelect ref={ref} onChange={e => onChange(e.target.value)} {...rest} />
))
