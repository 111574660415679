import { findIndex, get } from 'lodash'
import React, { useRef } from 'react'
import styled from 'styled-components'

import useConfigContext from '../../config-context'
import Formy from '../../formy'
import Button from '../../ui/button'
import { usePreview, previewHosts } from '../../components/use-preview'

const Wrapper = styled.div`
  width: 800px;
  margin: 0 auto;
  margin-top: 50px;
`

const useProcessPreview = i => {
  const { config, configRef } = useConfigContext()
  const host = configRef.current.meta.host || previewHosts[0]
  const [start, update, previewing] = usePreview(`${host}/preview/progress`)
  const progressKeyRef = useRef(
    get(config, `forms.${i}.process.processSteps.0.key`, null)
  )
  const realUpdate = newKey => {
    if (newKey) progressKeyRef.current = newKey
    const form = configRef.current.forms[i]
    const processes = get(form, 'process.processSteps', [])
    const progress = []
    for (let i = 0; i < processes.length; ++i) {
      const { key, dateRequired } = processes[i]
      const p = { status: key, date: new Date() }
      if (dateRequired) p.action_date = new Date() // eslint-disable-line
      progress.push(p)
      if (key === progressKeyRef.current) break
    }
    update({ form, progress })
  }
  const realStart = () => start(realUpdate)
  return [realStart, realUpdate, previewing]
}

export default ({ id }) => {
  const { config, updateConfig } = useConfigContext()
  const i = findIndex(config.forms, { id })
  const [startPreview, updatePreview, previewing] = useProcessPreview(i)
  return (
    <Wrapper>
      <Button onClick={startPreview}>
        {previewing ? 'Preview Opened' : 'Open External Preview'}
      </Button>
      <Formy
        onChange={newValue => {
          updateConfig(draft => {
            draft.forms[i].process = newValue
          })
          setTimeout(updatePreview)
        }}
        value={config.forms[i].process || {}}
      >
        {({ Section, Row, Column, ...Fields }) => (
          <Fields.List formKey='processSteps' label='Process Steps'>
            {(deleteButton, i, val) => (
              <Section label={`Step ${i + 1}`}>
                <Fields.Text formKey={`processSteps.${i}.key`} label='Key' />
                <Section label='Progress Page'>
                  <Row>
                    <Fields.Text
                      formKey={`processSteps.${i}.unfinishedStatus`}
                      label='Status (unfinished)'
                    />
                    <Fields.Text
                      formKey={`processSteps.${i}.finishedStatus`}
                      label='Status (finished)'
                    />
                  </Row>
                  <Fields.Textarea
                    formKey={`processSteps.${i}.description`}
                    label='Description'
                  />
                </Section>
                <Section label='Admin Page'>
                  <Fields.Checkbox
                    formKey={`processSteps.${i}.approvable`}
                    label='Is Approvable'
                  />
                  <Fields.Text
                    formKey={`processSteps.${i}.dialogButtonText`}
                    label='Open Dialog (or submit if no date) Button Text'
                  />
                  <Row>
                    <Fields.Checkbox
                      formKey={`processSteps.${i}.dateRequired`}
                      label='Needs Date'
                    />
                    <Fields.Text
                      formKey={`processSteps.${i}.datePickerLabel`}
                      label='Date Picker Text'
                    />
                    <Fields.Dropdown
                      label='Date Picker Filter Key'
                      formKey={`processSteps.${i}.datePickerFilterKey`}
                      options={[
                        { id: '', label: 'none' },
                        {
                          id: 'everyOtherWednesday',
                          label: 'Every Other Wednesday'
                        }
                      ]}
                    />
                    <Fields.Text
                      formKey={`processSteps.${i}.submitButtonText`}
                      label='Submit Button Text'
                    />
                  </Row>
                  <Fields.Text
                    formKey={`processSteps.${i}.urlSuffix`}
                    label='/api/:route/:id/:WHAT_IS_THIS_NAME_HERE'
                  />
                  <Fields.Text
                    formKey={`processSteps.${i}.successMsg`}
                    label='Success Text'
                  />
                </Section>
                {deleteButton}
                {previewing && (
                  <Button onClick={() => updatePreview(val.key)}>
                    Preview this step
                  </Button>
                )}
              </Section>
            )}
          </Fields.List>
        )}
      </Formy>
    </Wrapper>
  )
}
