import { Link, navigate } from '@reach/router'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import shortid from 'shortid'
import styled from 'styled-components'

import useConfigContext from '../config-context'
import Button from '../ui/button'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const UL = styled.ul`
  list-style: none;
  padding: 0;
  width: 800px;
  margin: 0 auto;
  margin-top: 35px;
`

const LI = styled.li`
  background: ${p => (p.enabled ? 'white' : '#ccc')};
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`

const A = styled(Link)`
  color: #545f66;
  text-decoration: none;
  padding: 20px 10px;
  display: block;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    color: #99a5ab;
  }
`

const I = styled.i`
  color: #545f66;
  user-select: none;
`

const CreateButton = styled(Button)`
  margin-top: 15px;
`

export default () => {
  const { config, updateConfig } = useConfigContext()

  function createNewForm () {
    const id = shortid.generate()
    updateConfig(draft => {
      draft.forms.push({
        id,
        meta: {
          name: '',
          enabled: true,
          context: '',
          schemaModule: '',
          pluralModule: '',
          route: '',
          pdfLink: '',
          emails: []
        },
        process: {
          confirmationTitle: '',
          confirmationMessage: '',
          processSteps: []
        },
        headers: [],
        logic: '',
        gadgets: [],
        template: {}
      })
    })
    navigate(`/forms/${id}`)
  }

  function removeForm (i) {
    updateConfig(draft => {
      draft.forms.splice(i, 1)
    })
  }

  return (
    <DragDropContext
      onDragEnd={result => {
        if (!result.destination) return
        updateConfig(draft => {
          const [item] = draft.forms.splice(result.source.index, 1)
          draft.forms.splice(result.destination.index, 0, item)
        })
      }}
    >
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <Wrapper>
            <UL {...provided.droppableProps} ref={provided.innerRef}>
              {config.forms.map((form, i) => (
                <Draggable key={form.id} draggableId={form.id} index={i}>
                  {(provided, snapshot) => (
                    <LI
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      enabled={form.meta.enabled}
                    >
                      <I
                        className='material-icons'
                        {...provided.dragHandleProps}
                      >
                        drag_handle
                      </I>
                      <A to={`/forms/${form.id}`}>
                        {form.meta.name || 'Untitled Form'}
                      </A>
                      <Button red onClick={() => removeForm(i)}>
                        Delete
                      </Button>
                    </LI>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </UL>
            <CreateButton onClick={createNewForm}>Create New Form</CreateButton>
          </Wrapper>
        )}
      </Droppable>
    </DragDropContext>
  )
}
