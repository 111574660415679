import React, { useRef } from 'react'
import styled from 'styled-components'

import useConfigContext from './config-context'
import S_Textarea from './ui/textarea'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
`

const Textarea = styled(S_Textarea)`
  resize: none;
  width: 900px;
  max-width: 100vw;
  height: 700px;
  max-height: calc(100vh - 200px);
`

const H1 = styled.h1`
  width: 800px;
  text-align: center;
`

const ErrorPage = () => {
  const ref = useRef()
  const { config } = useConfigContext()
  function onClick () {
    ref.current.select()
  }
  return (
    <Wrapper>
      <H1>
        An error has occurred. Check the console for details. Here's what your
        JSON was at the time of the error:
      </H1>
      <Textarea
        ref={ref}
        onMouseUp={onClick}
        value={JSON.stringify(config, null, 2)}
      />
    </Wrapper>
  )
}

export class ErrorBoundary extends React.Component {
  state = { error: null }

  componentDidCatch (error) {
    console.error(error)
    this.setState({ error })
  }

  render () {
    if (!this.state.error) return this.props.children
    return <ErrorPage />
  }
}

export class AppErrorBoundary extends React.Component {
  state = { error: null }

  componentDidCatch (error) {
    console.error(error)
    this.setState({ error })
  }

  render () {
    if (!this.state.error) return this.props.children
    return <H1>Woah, Woah, Woah. Something went way wrong.</H1>
  }
}
