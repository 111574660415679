import React, { useReducer, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import exampleJSON from './example.json'
import * as ls from './storage'
import S_Alert from './ui/alert'
import S_Button from './ui/button'
import S_Textarea from './ui/textarea'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
`
const Button = styled(S_Button)`
  margin: 0 20px;
`
const Span = styled.span`
  padding: 20px 0;
`
const Label = styled.label``
const Textarea = styled(S_Textarea)`
  resize: none;
  width: 900px;
  max-width: 100vw;
  height: 700px;
  max-height: calc(100vh - 300px);
  margin: 5px 0;
`
const Alert = styled(S_Alert)`
  margin-bottom: 5px;
`

const initialState = { config: '', error: null }

function reducer (state, action) {
  switch (action.type) {
    case 'update':
      return { config: action.data, error: null }
    case 'error':
      return { config: state.config, error: action.data }
    default:
      throw new Error()
  }
}

export default function Prescreen ({ save }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const createNew = useCallback(() => {
    save({ meta: { name: '', module: '', emails: [] }, forms: [] })
  }, [save])
  const createExample = useCallback(() => {
    save(exampleJSON)
  }, [save])
  const saved = useMemo(() => ls.load() || null, [])
  const injest = () => {
    let config
    try {
      config = JSON.parse(state.config)
    } catch (e) {
      dispatch({ type: 'error', data: e.message })
      return
    }
    save(config)
  }
  return (
    <Wrapper>
      <div>
        <Button onClick={createNew}>Create New JSON</Button>
        <Button onClick={createExample}>Use Example JSON</Button>
        {saved && (
          <Button onClick={() => save(saved)}>Use from LocalStorage</Button>
        )}
      </div>
      <Span>or</Span>
      <Label>Paste your existing JSON here</Label>
      <Textarea
        value={state.config}
        onChange={data => dispatch({ type: 'update', data })}
      />
      {state.error && <Alert title='Could not parse JSON'>{state.error}</Alert>}
      <div>
        <Button disabled={!state.config} onClick={injest}>
          Injest
        </Button>
        {saved && (
          <Button
            onClick={() =>
              dispatch({ type: 'update', data: JSON.stringify(saved, null, 2) })
            }
          >
            Paste from LocalStorage
          </Button>
        )}
      </div>
    </Wrapper>
  )
}
