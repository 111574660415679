import { find } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { DragSource } from 'react-dnd'
import styled from 'styled-components'

import DefaultAssembler from './default-assembler'
import * as logicFns from './logic'
import Gadgets from '../../../gadgets'

const Draggable = DragSource(
  'GADGET',
  {
    beginDrag: props => {
      props.setIsDragging(true)
      return { alpha: props.alpha }
    },
    endDrag: props => {
      props.setIsDragging(false)
    }
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
)(({ isDragging, connectDragSource, children, onClick }) => {
  const opacity = isDragging ? 0.2 : 1
  return connectDragSource(
    <div style={{ opacity }} onClick={onClick}>
      {children}
    </div>
  )
})

const ConfigWrapper = ({ children, type, alpha, onDragStart }) => {
  const ref = useRef()
  useEffect(
    () =>
      onDragStart(alpha, () => {
        if (!ref.current) return []
        const rect = ref.current.getBoundingClientRect()
        const { top, left, bottom, right, width, height } = rect
        const points = []
        if (type === 'initial') {
          return [
            {
              x: left + width / 2,
              y: top + height / 2,
              alpha,
              dir: 'initial',
              rect
            }
          ]
        }
        const padding = ['Row', 'Column'].includes(type) ? 0 : 5
        if (type !== 'Column') {
          points.push({
            x: left + width / 2,
            y: top + padding,
            alpha,
            dir: 'top',
            rect
          })
          points.push({
            x: left + width / 2,
            y: bottom - padding,
            alpha,
            dir: 'down',
            rect
          })
        }
        if (type !== 'Row') {
          points.push({
            x: left + padding,
            y: top + height / 2,
            alpha,
            dir: 'left',
            rect
          })
          points.push({
            x: right - padding,
            y: top + height / 2,
            alpha,
            dir: 'right',
            rect
          })
        }
        return points
      }),
    [alpha, onDragStart, type]
  )
  return <div ref={ref}>{children}</div>
}

const InitialZone = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
`

const InitialSectionZone = styled.div`
  padding: 50px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`

export default ({
  logic,
  gadgets,
  onDragStart,
  setIsDragging,
  setSelected
}) => {
  if (!logic) {
    return (
      <ConfigWrapper type='initial' onDragStart={onDragStart}>
        <InitialZone>DROP SOMETHING HERE</InitialZone>
      </ConfigWrapper>
    )
  }
  const form = logicFns.mapLogic(logic, (alpha, children) => {
    const gadget = find(gadgets, { alpha })
    const Gadget = Gadgets[gadget.type] || Gadgets.Generic
    const Assembler = Gadget.Assembler || DefaultAssembler
    const value = Gadget.initialValue ? Gadget.initialValue() : ''
    if (gadget.type === 'Section' && !children.length) {
      children.push(
        <ConfigWrapper
          alpha={`-${alpha}`}
          type='initial'
          onDragStart={onDragStart}
        >
          <InitialSectionZone>DROP SOMETHING HERE</InitialSectionZone>
        </ConfigWrapper>
      )
    }
    let result = (
      <ConfigWrapper
        alpha={alpha}
        type={gadget.type}
        onDragStart={onDragStart}
        key={alpha}
      >
        <Assembler
          label={gadget.label}
          // description={gadget.description}
          component={
            <Gadget.Edit
              type={gadget.type}
              config={gadget.config}
              value={value}
              onChange={() => null}
              children={children}
            />
          }
        />
      </ConfigWrapper>
    )
    if (!['Row', 'Column'].includes(gadget.type)) {
      result = (
        <Draggable
          alpha={alpha}
          setIsDragging={setIsDragging}
          key={alpha}
          onClick={e => {
            e.stopPropagation()
            setSelected(alpha)
          }}
        >
          {result}
        </Draggable>
      )
    }
    return result
  })
  return <div>{form}</div>
}
