import React from 'react'

export const Edit = ({ value, onChange }) => (
  <input
    type='checkbox'
    checked={value}
    onChange={e => onChange(e.target.checked)}
  />
)

export const initialValue = () => false
