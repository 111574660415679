import { findIndex } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import useConfigContext from '../../config-context'
import Formy from '../../formy'
import Button from '../../ui/button'
import { usePreview, previewHosts } from '../../components/use-preview'

const Wrapper = styled.div`
  width: 800px;
  margin: 0 auto;
  margin-top: 50px;
`

const useConfirmationPreview = i => {
  const { configRef } = useConfigContext()
  const host = configRef.current.meta.host || previewHosts[0]
  const [start, update, previewing] = usePreview(`${host}/preview/confirmation`)
  const realUpdate = () => update({ form: configRef.current.forms[i] })
  const realStart = () => start(realUpdate)
  return [realStart, realUpdate, previewing]
}

export default ({ id }) => {
  const { config, updateConfig } = useConfigContext()
  const i = findIndex(config.forms, { id })
  const [startPreview, updatePreview, previewing] = useConfirmationPreview(i)
  return (
    <Wrapper>
      <Button onClick={startPreview}>
        {previewing ? 'Preview Opened' : 'Open External Preview'}
      </Button>
      <Formy
        onChange={newValue => {
          updateConfig(draft => {
            draft.forms[i].process = newValue
          })
          setTimeout(updatePreview)
        }}
        value={config.forms[i].process || {}}
      >
        {({ Section, Row, Column, ...Fields }) => (
          <Section label='Confirmation Page'>
            <Fields.Text formKey='confirmationTitle' label='Title' />
            <Fields.Textarea formKey='confirmationMessage' label='Message' />
          </Section>
        )}
      </Formy>
    </Wrapper>
  )
}
