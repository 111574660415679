import { cloneDeep } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Button from '../ui/button'
import Text from '../ui/text'

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
`

export const Edit = ({ config, value, onChange }) => (
  <>
    {value.map((val, i) => (
      <Row>
        <Text
          placeholder={config.placeholder}
          value={val}
          onChange={newVal => {
            const vals = cloneDeep(value)
            vals[i] = newVal
            onChange(vals)
          }}
        />
        <Button
          red
          onClick={() => {
            const vals = cloneDeep(value)
            vals.splice(i, 1)
            onChange(vals)
          }}
        >
          Delete
        </Button>
      </Row>
    ))}
    <Button onClick={() => onChange([...value, ''])}>Add</Button>
  </>
)

export const initialValue = () => []
