import { cloneDeep } from 'lodash'
import React from 'react'

import Button from '../ui/button'

// make this beautifully drag-n-drop able
// todo:: somehow figure out what you're rendering and build a real default value for it
// todo:: can this be used for non-objects? like our text list?
export const Edit = ({ children, value, onChange }) => (
  <>
    {value.map((val, i) =>
      children(
        <Button
          red
          onClick={() => {
            const vals = cloneDeep(value)
            vals.splice(i, 1)
            onChange(vals)
          }}
        >
          Delete
        </Button>,
        i,
        val
      )
    )}
    <Button onClick={() => onChange([...value, {}])}>Add</Button>
  </>
)

export const initialValue = () => []
