import { filter, findIndex, map } from 'lodash'
import React, { useRef } from 'react'
import styled from 'styled-components'

import useConfigContext from '../../config-context'
import Formy from '../../formy'
import Text from '../../ui/text'

const Wrapper = styled.div`
  width: 800px;
  margin: 0 auto;
  margin-top: 50px;
`

const toElixir = type => {
  if (type === 'Text') return 'string'
  if (type === 'StateDropdown') return 'string'
  if (type === 'Date') return 'utc_datetime'
  if (type === 'Textarea') return 'text'
  console.error(`Cannot convert gadget type to elixir type: ${type}`)
  return 'UNKNOWN'
}

export default ({ id }) => {
  const { config, updateConfig } = useConfigContext()
  const i = findIndex(config.forms, { id })
  const gadgets = filter(config.forms[i].gadgets, 'formKey')
  const strs = map(gadgets, g => `${g.formKey}:${toElixir(g.type)}`).join(' ')
  const { context, schemaModule, pluralModule } = config.forms[i].meta
  return (
    <Wrapper>
      <Formy
        onChange={newValue =>
          updateConfig(draft => {
            draft.forms[i].meta = newValue
          })
        }
        value={config.forms[i].meta}
      >
        {({ Section, Row, Column, ...Fields }) => (
          <>
            <Section label='Form Details'>
              <Row>
                <Fields.Text formKey='name' label='Name' />
                <Fields.Checkbox formKey='enabled' label='Enabled' />
              </Row>
              <Row>
                <Fields.Text formKey='route' label='Route' />
                <Fields.Text formKey='pdfLink' label='Link to PDF' />
              </Row>
              <Fields.TextList formKey='emails' label='Emails' />
            </Section>
            <Section label='Elixir Stuff'>
              <Row>
                <Fields.Text formKey='context' label='Context' />
                <Fields.Text formKey='schemaModule' label='Schema Module' />
                <Fields.Text formKey='pluralModule' label='Plural Module' />
              </Row>
              <Fields.Wrap label='phx.gen.json command'>
                <SelfSelectingText
                  value={`mix phx.gen.json ${context} ${schemaModule} ${pluralModule} ${strs}`}
                />
              </Fields.Wrap>
            </Section>
          </>
        )}
      </Formy>
    </Wrapper>
  )
}

function SelfSelectingText ({ value }) {
  const ref = useRef()
  function onClick () {
    ref.current.select()
  }
  return <Text ref={ref} onMouseUp={onClick} value={value} readOnly />
}
