import React from 'react'
import styled from 'styled-components'

export const justForLooks = true

const Wrapper = styled.div`
  padding: 30px 40px;
  color: #99a5ab;
  background: #e4e5e9;
  font-size: 30px;
  &::after {
    content: 'X';
    border-bottom: 3px solid #99a5ab;
    display: block;
    width: 100%;
  }
`

export const Edit = () => <Wrapper />
