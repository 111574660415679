import styled from 'styled-components'

export default styled.button`
  border: none;
  cursor: pointer;
  user-select: none;
  color: white;
  background: ${props => (props.red ? '#e05554' : '#1f8ceb')};
  padding: 10px 20px;
  font-size: 16px;

  &:hover {
    background: ${props => (props.red ? '#cc504f' : '#1c7cd2')};
  }

  &:active {
    background: ${props => (props.red ? '#bb4948' : '#176cb8')};
  }

  &:disabled {
    cursor: not-allowed;
    background: #08365e;
    color: #c5c7cc;
  }
`
