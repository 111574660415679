import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background: #fff;
  border-radius: 5px;
  border: 1px solid #c5c7cc;
  width: 100%;
  margin: 20px 0;
`

const Label = styled.label`
  display: block;
  font-weight: 300;
  font-size: 25px;
  padding: 20px 40px;
  border-bottom: 1px solid #c5c7cc;
`

const Wrapper = styled.div`
  padding: 10px;
`

export const Edit = ({ children }) => <Wrapper>{children}</Wrapper>

export const Assembler = ({ label, description, component }) => (
  <Container>
    <Label>{label}</Label>
    {component}
  </Container>
)
