const { compact, find, forEach, map } = require('lodash')

function tokenize (logic) {
  const tokens = []
  for (let i = 0; i < logic.length; ++i) {
    const char = logic[i]
    if (char === '(') tokens.push({ type: 'OPEN' })
    else if (char === ')') tokens.push({ type: 'CLOSE' })
    else if (char === ',') tokens.push({ type: 'SEPARATOR' })
    else if (/[A-Z]/.test(char)) {
      const result = /[A-Z]+/.exec(logic.substr(i))
      if (!result || result.index !== 0) throw new Error('Internal Error')
      tokens.push({ type: 'IDENTIFIER', value: result[0] })
      i += result[0].length - 1
    } else {
      throw new Error(`Invalid Character: ${char}`)
    }
  }
  return tokens
}

function ensure (value, type) {
  if (!value || value.type !== type) throw new Error('Incorrect Format')
  return value.value
}

function evaluate (tokens) {
  const alpha = ensure(tokens.shift(), 'IDENTIFIER')
  if (tokens.length === 0) return { alpha, children: [] }
  ensure(tokens.shift(), 'OPEN')
  ensure(tokens.pop(), 'CLOSE')
  const innerTokens = []
  const nums = { prev: 0, paren: 0 }
  forEach(tokens, ({ type }, i) => {
    if (type === 'OPEN') nums.paren++
    if (type === 'CLOSE') nums.paren--
    if (type === 'SEPARATOR' && nums.paren === 0) {
      innerTokens.push(tokens.slice(nums.prev, i))
      nums.prev = i + 1
    }
  })
  innerTokens.push(tokens.slice(nums.prev))
  return { alpha, children: map(innerTokens, evaluate) }
}

export const parse = logic => {
  if (!logic) return null
  return evaluate(tokenize(logic))
}

export function stringify (obj) {
  if (!obj) return null
  return (obj.children || []).length > 0
    ? `${obj.alpha}(${map(obj.children, stringify)})`
    : obj.alpha
}

function getNextKey (key) {
  if (key === 'Z') return 'AA'
  const lastChar = key.slice(-1)
  const sub = key.slice(0, -1)
  if (lastChar === 'Z') return getNextKey(sub) + 'A'
  return sub + String.fromCharCode(lastChar.charCodeAt() + 1)
}

export const nextAvailableKey = logic => {
  const letters = compact(logic.split(/[,|(|)]/))
  let nextKey = 'A'
  while (letters.includes(nextKey)) nextKey = getNextKey(nextKey)
  return nextKey
}

export const mapLogic = (logic, fn) => {
  const obj = parse(logic)
  const inner = arr => map(arr, o => fn(o.alpha, inner(o.children)))
  return inner([obj])[0]
}

export const buildTemplate = ({ logic, gadgets }) => {
  if (!logic) return {}
  return mapLogic(logic, (alpha, children) => {
    const obj = Object.assign({}, find(gadgets, { alpha }))
    if (children.length) obj.children = children
    return obj
  })
}
