import React, { useContext, useLayoutEffect, useRef } from 'react'

export const ConfigContext = React.createContext(null)

export default () => {
  const { config, updateConfig } = useContext(ConfigContext)
  const configRef = useRef(config)
  useLayoutEffect(() => {
    configRef.current = config
  }, [config])
  return { config, configRef, updateConfig }
}
