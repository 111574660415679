import React from 'react'
import styled from 'styled-components'

import useConfigContext from '../config-context'
import Formy from '../formy'

const Wrapper = styled.div`
  width: 800px;
  margin: 0 auto;
  margin-top: 50px;
`

export default () => {
  const { config, updateConfig } = useConfigContext()
  return (
    <Wrapper>
      <Formy
        onChange={newValue =>
          updateConfig(draft => {
            draft.meta = newValue
          })
        }
        value={config.meta}
      >
        {({ Section, Row, Column, ...Fields }) => (
          <Section label='App Information'>
            <Row>
              <Fields.Text formKey='name' label='Name' />
              <Fields.Text formKey='host' label='Host' />
            </Row>
            <Fields.Text formKey='module' label='Module' />
            <Fields.TextList formKey='emails' label='Emails' />
          </Section>
        )}
      </Formy>
    </Wrapper>
  )
}
