import React from 'react'
import { DragDropContextProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import { createGlobalStyle } from 'styled-components'

import App from './app'
import { AppErrorBoundary } from './error-boundary'

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
  }

  body {
    margin: 0;
    background: #f0f3f6;
  }
`

const el = document.getElementById('root')
ReactDOM.render(
  <AppErrorBoundary>
    <DragDropContextProvider backend={HTML5Backend}>
      <GlobalStyle />
      <App />
    </DragDropContextProvider>
  </AppErrorBoundary>,
  el
)
