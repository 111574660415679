import React, { useRef } from 'react'
import styled from 'styled-components'

import useConfigContext from '../config-context'
// import Button from '../ui/button'
import S_Textarea from '../ui/textarea'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
`

const Textarea = styled(S_Textarea)`
  resize: none;
  width: 900px;
  max-width: 100vw;
  height: 700px;
  max-height: calc(100vh - 300px);
`

export default () => {
  const ref = useRef()
  const { config } = useConfigContext()
  function onClick () {
    ref.current.select()
  }
  return (
    <Wrapper>
      <Textarea
        ref={ref}
        onMouseUp={onClick}
        value={JSON.stringify(config, null, 2)}
      />
      {/* <div>
        <Button>Copy</Button>
        <Button>Download</Button>
      </div> */}
    </Wrapper>
  )
}
