import { useRef, useState, useCallback } from 'react'

export const previewHosts = [
  // TODO:: isWsl needs to come from ENV
  // isWsl ? 'http://linuxhost:3000' : 'http://localhost:3000',
  'https://salem.formal.website'
]

export const usePreview = url => {
  const previewRef = useRef(null)
  const [previewing, setPreviewing] = useState(false)
  const startPreview = useCallback(
    cb => {
      const target = window.open(url, 'LyzerPreview', 'width=1500,height=1500')
      previewRef.current = target
      setPreviewing(true)
      const init = e => {
        if (e.data.source === 'lyzer-preview' && e.data.message === 'ready') {
          cb()
          window.removeEventListener('message', init)
        }
      }
      window.addEventListener('message', init)
    },
    [url]
  )
  const updatePreview = useCallback(data => {
    if (previewRef.current) {
      previewRef.current.postMessage({ source: 'lyzer', data }, '*')
    }
  }, [])
  return [startPreview, updatePreview, previewing]
}
