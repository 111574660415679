import { filter, findIndex, keyBy, mapValues } from 'lodash'
import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'

import useConfigContext from '../../config-context'
import S_Button from '../../ui/button'
import Toggle from '../../ui/toggle'

// We should be able to set default sorts and filters.
// Will we want >1 list view for each form?

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PreviewWrapper = styled.div`
  display: flex;
`

const ConfigWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const Columns = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  margin-bottom: 30px;
`

const ColumnHeader = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  border-bottom: 1px solid #c5c7cc;
  text-align: center;
  white-space: nowrap;
`

const ColumnCell = styled.div`
  background: white;
  padding: 20px 30px;
  &::after {
    content: '';
    background: ${props => props.coloor || '#c5c7cc'};
    height: 40px;
    display: block;
    min-width: 100px;
  }
`

const ColumnWrapper = styled.div`
  border: 1px solid #c5c7cc;
`

const Button = styled(S_Button)`
  margin-left: 5px;
  padding: 5px;
  padding-bottom: 3px;
`

const Column = React.forwardRef(({ children, remove, ...rest }, ref) => (
  <ColumnWrapper ref={ref} {...rest}>
    <ColumnHeader>
      {children}
      {remove && (
        <Button red onClick={remove}>
          <i className='material-icons'>delete</i>
        </Button>
      )}
    </ColumnHeader>
    <ColumnCell />
    <ColumnCell />
    <ColumnCell />
    <ColumnCell />
    <ColumnCell />
  </ColumnWrapper>
))

const UL = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  height: calc(100vh - 132px);
  min-height: 512px;
  overflow: auto;
`

const LI = styled.li`
  background: #fff;
  border: 1px solid #c5c7cc;
  border-right: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 20px;
  width: 230px;
  &:not(:last-child) {
    border-bottom: none;
  }
  &:hover {
    background: #f0f3f6;
  }
  &::before {
    content: '';
    width: 20px;
    height: 70px;
    font-family: 'Material Icons';
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  &:hover::before {
    content: 'add';
    background: #1f8ceb;
  }
`

const Scrollable = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
`

const Empty = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  font-size: 48px;
  color: #c5c7cc;
  width: 230px;
`

const ColumnOptions = ({ nameMap, options, onClick }) => {
  if (!options.length) return <Empty>(Empty)</Empty>
  return (
    <UL>
      {options.map(option => (
        <LI key={option} onClick={() => onClick(option)}>
          {nameMap[option] || `[${option}]`}
        </LI>
      ))}
    </UL>
  )
}

export default ({ id }) => {
  const [preview, setPreview] = useState(false)
  const { config, updateConfig } = useConfigContext()
  const i = findIndex(config.forms, { id })
  const { gadgets, headers } = config.forms[i]
  const filteredGadgets = filter(gadgets, 'formKey')
  const nameMap = mapValues(keyBy(filteredGadgets, 'formKey'), 'label')
  const allOptions = Object.keys(nameMap)
  const options = filter(allOptions, option => !headers.includes(option))
  return (
    <>
      <ToggleWrapper>
        <Toggle on='preview' off='edit' value={preview} onChange={setPreview} />
      </ToggleWrapper>
      {preview ? (
        <PreviewWrapper>
          <Scrollable>
            <Columns>
              {headers.map((el, j) => (
                <Column key={el}>{nameMap[el] || el}</Column>
              ))}
            </Columns>
          </Scrollable>
        </PreviewWrapper>
      ) : (
        <ConfigWrapper>
          <ColumnOptions
            options={options}
            nameMap={nameMap}
            onClick={el =>
              updateConfig(draft => {
                draft.forms[i].headers.push(el)
              })
            }
          />
          <Scrollable>
            <DragDropContext
              onDragEnd={result => {
                if (!result.destination) return
                updateConfig(draft => {
                  const [item] = draft.forms[i].headers.splice(
                    result.source.index,
                    1
                  )
                  draft.forms[i].headers.splice(
                    result.destination.index,
                    0,
                    item
                  )
                })
              }}
            >
              <Droppable droppableId='droppable' direction='horizontal'>
                {(provided, snapshot) => (
                  <Columns {...provided.droppableProps} ref={provided.innerRef}>
                    {headers.map((el, j) => (
                      <Draggable key={el} draggableId={el} index={j}>
                        {(provided, snapshot) => (
                          <Column
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            remove={() => {
                              updateConfig(draft => {
                                draft.forms[i].headers.splice(j, 1)
                              })
                            }}
                          >
                            {nameMap[el] || el}
                          </Column>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Columns>
                )}
              </Droppable>
            </DragDropContext>
          </Scrollable>
        </ConfigWrapper>
      )}
    </>
  )
}
