import { Router } from '@reach/router'
import { findIndex } from 'lodash'
import React from 'react'

import Configure from './configure'
import Details from './details'
import List from './list'
import Confirmation from './confirmation'
import Payment from './payment'
import Process from './process'
import HeaderMenu from '../../ui/header-menu'
import useConfigContext from '../../config-context'
import NotFound from '../not-found'

export default ({ id }) => {
  const { config } = useConfigContext()
  const i = findIndex(config.forms, { id })
  if (i === -1) return <NotFound />
  return (
    <>
      <HeaderMenu
        options={[
          {
            id: 'details',
            label: 'Details',
            href: `/forms/${id}`,
            exact: true
          },
          { id: 'config', label: 'Configure', href: `/forms/${id}/configure` },
          { id: 'headers', label: 'List View', href: `/forms/${id}/list` },
          { id: 'confirmation', label: 'Confirmation', href: `/forms/${id}/confirmation` },
          { id: 'payment', label: 'Payment', href: `/forms/${id}/payment` },
          { id: 'process', label: 'Process', href: `/forms/${id}/process` }
        ]}
      />
      <Router>
        <Details path='/' />
        <Configure path='/configure' />
        <List path='/list' />
        <Confirmation path='/confirmation' />
        <Payment path='/payment' />
        <Process path='/process' />
      </Router>
    </>
  )
}
