import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

const SIZE = 40

const Wrapper = styled.input`
  appearance: none;
  width: 100px;
  height: ${SIZE}px;
  background: #fff;
  border-radius: ${SIZE}px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &::after {
    content: '';
    height: ${SIZE - 4}px;
    border-radius: ${SIZE - 4}px;
    background: ${props =>
    ['animated', 'unanimating'].includes(props.state)
      ? ' #1fd393'
      : '#e05554'};
    margin: 2px;
    position: absolute;
    transition: all 150ms;
    left: 0px;
    left: ${props =>
    props.state === 'animated' ? `calc(100% - ${SIZE}px)` : 0};
    right: ${props => (props.state ? 0 : `calc(100% - ${SIZE}px)`)};
    top: 0;
  }

  &::before {
    content: "${props => props.off}";
    margin-left: ${SIZE}px;
    margin-right: 0px;
    width: calc(100% - ${SIZE}px);
    text-align: center;
    ${props =>
    ['animated', 'unanimating'].includes(props.state) &&
      css`
        content: "${props => props.on}";
        margin-left: 0px;
        margin-right: ${SIZE}px;
    `}
  }
`

export default React.forwardRef(({ value, onChange, ...rest }, ref) => {
  const [state, setState] = useState(value ? 'animated' : '')
  const mountedRef = useRef()
  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true
      return
    }
    setState(value ? 'animating' : 'unanimating')
    const timeoutId = setTimeout(() => setState(value ? 'animated' : ''), 150)
    return () => clearTimeout(timeoutId)
  }, [value])
  return (
    <Wrapper
      type='checkbox'
      pose={value ? 'on' : 'off'}
      state={state}
      ref={ref}
      checked={value}
      onChange={e => onChange(e.target.checked)}
      {...rest}
    />
  )
})
