import { Router } from '@reach/router'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { ConfigContext } from './config-context'
import { ErrorBoundary } from './error-boundary'
import * as ls from './storage'
import Info from './pages/info'
import Forms from './pages/forms'
import FormsId from './pages/forms-id'
import Export from './pages/export'
import NotFound from './pages/not-found'
import Prescreen from './prescreen'
import S_HeaderMenu from './ui/header-menu'

const HeaderMenu = styled(S_HeaderMenu)`
  z-index: 1;
`

export default function App () {
  const [config, updateConfig] = useImmer(null)
  useEffect(() => {
    if (config) ls.save(config)
  })
  if (!config) return <Prescreen save={config => updateConfig(() => config)} />
  return (
    <ConfigContext.Provider value={{ config, updateConfig }}>
      <ErrorBoundary>
        <HeaderMenu
          options={[
            { id: 'info', label: 'Info', href: '/', exact: true },
            {
              id: 'forms',
              label: 'Forms',
              href: '/forms',
              arrow: location => location.pathname !== '/forms'
            },
            { id: 'export', label: 'Export', href: '/export' }
          ]}
        />
        <Router>
          <Info path='/' />
          <Forms path='/forms' />
          <FormsId path='/forms/:id/*' />
          <Export path='/export' />
          <NotFound default />
        </Router>
      </ErrorBoundary>
    </ConfigContext.Provider>
  )
}
