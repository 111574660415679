import React from 'react'
import styled from 'styled-components'

const BigText = styled.div`
  font-size: 50px;
  text-align: center;
  background: #e05554;
  color: #ffffff80;
  padding: 40px;

  &::after {
    content: 'Not implemented';
    font-size: 15px;
    display: block;
  }
`

export const Edit = ({ type }) => <BigText>{type}</BigText>
