import * as d3 from 'd3'
import { compact, find, findIndex, keyBy, mapValues } from 'lodash'
import React, { useRef, useState, useEffect } from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import shortid from 'shortid'
import styled from 'styled-components'

import FormalyzerConfig from './formalyzer-config'
import * as logicFns from './logic'
import Gadgets from '../../../gadgets'
import Button from '../../../ui/button'
import useConfigContext from '../../../config-context'
import Formy from '../../../formy'
import { usePreview, previewHosts } from '../../../components/use-preview'

// We may want the ability to create multiple views into a form.
// We may want this to be separated into two parts.
// 1) define which data elements exist for this form.
// 2) create n number of forms based on those data elements.

const DraggableLi = DragSource(
  'GADGET',
  {
    beginDrag: props => {
      props.setIsDragging(true)
      return { type: props.children }
    },
    endDrag: props => {
      props.setIsDragging(false)
    }
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
)(({ isDragging, connectDragSource, children }) => {
  const opacity = isDragging ? 0.2 : 1
  return connectDragSource(<li style={{ opacity }}>{children}</li>)
})

const gadgetKeys = Object.keys(Gadgets).filter(
  key => !['Column', 'Row', 'Generic'].includes(key)
)

const ListOfGadgets = ({ setIsDragging }) => (
  <ul>
    {gadgetKeys.map(key => (
      <DraggableLi key={key} setIsDragging={setIsDragging}>
        {key}
      </DraggableLi>
    ))}
  </ul>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 800px;
  margin: 0 auto;
`

const SidebarWrapper = styled.div`
  position: absolute;
  left: calc(100% + 50px);
  min-width: 300px;
  z-index: 1;
`

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const DropzoneArea = styled.div`
  position: absolute;
  background: #1fd393;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`

const Dropzone = ({ d, parentRef }) => {
  // TODO:: don't show dropzone if the drop won't happen
  const p = parentRef.current.getBoundingClientRect()
  let top = d.rect.top - p.top
  let left = d.rect.left - p.left
  let width = d.rect.width
  let height = d.rect.height
  if (d.dir === 'left') width /= 2
  if (d.dir === 'right') width /= 2
  if (d.dir === 'top') height /= 2
  if (d.dir === 'down') height /= 2
  if (d.dir === 'right') left += width
  if (d.dir === 'down') top += height
  return <DropzoneArea top={top} left={left} width={width} height={height} />
}

const Path = DropTarget(
  'GADGET',
  {
    drop: (props, monitor) => {
      if (monitor.didDrop()) return
      props.onDrop(monitor.getItem(), props.data)
    },
    hover: props => props.onHover(props.data)
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget()
  })
)(({ connectDropTarget, d }) => {
  return connectDropTarget(<path d={d} fill='transparent' />)
})

function DropHandler ({ children, isDragging, onDrop }) {
  const ref = useRef()
  const registry = useRef({})
  const [polygons, setPolygons] = useState(null)
  const [dropzoneData, setDropzoneData] = useState(null)
  useEffect(() => {
    if (!ref.current) return
    if (isDragging) {
      const points = []
      for (const key in registry.current) {
        const pointss = registry.current[key]()
        points.push(...pointss)
      }
      const { width, height, top, left } = ref.current.getBoundingClientRect()
      const polygons = d3
        .voronoi()
        .x(p => p.x - left)
        .y(p => p.y - top)
        .size([width, height])
        .polygons(points)
      setPolygons(polygons)
    } else {
      setPolygons(null)
      setDropzoneData(null)
    }
  }, [isDragging])
  let grid = null
  if (polygons) {
    grid = (
      <SVG>
        {polygons.map(polygon => (
          <Path
            d={d3.line()(polygon)}
            data={polygon.data}
            onDrop={onDrop}
            onHover={data => setDropzoneData(data)}
          />
        ))}
      </SVG>
    )
  }
  return (
    <div ref={ref} style={{ position: 'relative' }}>
      {children((alpha, fn) => {
        registry.current[alpha] = fn
        return () => delete registry.current[alpha]
      })}
      {dropzoneData && <Dropzone d={dropzoneData} parentRef={ref} />}
      {grid}
    </div>
  )
}

const useFormPreview = i => {
  const { configRef } = useConfigContext()
  const host = configRef.current.meta.host || previewHosts[0]
  const [start, update, previewing] = usePreview(`${host}/preview/form`)
  const realUpdate = () => update({ form: configRef.current.forms[i] })
  const realStart = () => start(realUpdate)
  return [realStart, realUpdate, previewing]
}

export default ({ id }) => {
  const [isDragging, setIsDragging] = useState(false)
  const [selected, setSelected] = useState(null)
  const { config, updateConfig } = useConfigContext()
  const i = findIndex(config.forms, { id })
  const { logic, gadgets } = config.forms[i]
  const [startPreview, updatePreview, previewing] = useFormPreview(i)
  return (
    <Wrapper>
      <SidebarWrapper>
        <Button onClick={startPreview}>
          {previewing ? 'Preview Opened' : 'Open External Preview'}
        </Button>
        <ListOfGadgets setIsDragging={setIsDragging} />
        {selected && (
          <ConfigureBox
            key={selected}
            value={find(gadgets, { alpha: selected })}
            onChange={newValue => {
              updateConfig(draft => {
                const j = findIndex(gadgets, { alpha: selected })
                if (j === -1) return
                draft.forms[i].gadgets[j] = newValue
                draft.forms[i].template = logicFns.buildTemplate(draft.forms[i])
              })
              setTimeout(updatePreview)
            }}
            deleteMe={() => {
              updateConfig(draft => {
                const j = findIndex(gadgets, { alpha: selected })
                if (j === -1) return
                let obj = logicFns.parse(logic)
                obj = { children: [obj] }
                removeAlpha(obj, selected)
                cleanup(obj, gadgets)
                const newLogic = logicFns.stringify(obj.children[0])
                const letters = compact(newLogic.split(/[,|(|)]/))
                draft.forms[i].gadgets = draft.forms[i].gadgets.filter(g =>
                  letters.includes(g.alpha)
                )
                draft.forms[i].logic = newLogic
                draft.forms[i].template = logicFns.buildTemplate(draft.forms[i])
              })
              setTimeout(updatePreview)
            }}
          />
        )}
      </SidebarWrapper>
      <DropHandler
        isDragging={isDragging}
        onDrop={(item, { alpha, dir }) => {
          if (!alpha) {
            updateConfig(draft => {
              const Gadget = Gadgets[item.type]
              const config = Gadget.initialConfig ? Gadget.initialConfig() : {}
              draft.forms[i].logic = 'A'
              draft.forms[i].gadgets = [
                { alpha: 'A', type: item.type, label: 'Gimme a Name', config }
              ]
              if (item.type !== 'Section') {
                draft.forms[i].gadgets[0].formKey = shortid.generate()
              }
              draft.forms[i].template = logicFns.buildTemplate(draft.forms[i])
            })
            setTimeout(updatePreview)
            return
          }
          if (alpha.startsWith('-')) alpha = alpha.slice(1)
          if (item.alpha === alpha) return
          const gadgetTypeMap = mapValues(keyBy(gadgets, 'alpha'), 'type')
          if (gadgetTypeMap[item.alpha] === 'Section') {
            let obj = logicFns.parse(logic)
            const self = findSelf(obj, item.alpha)
            const str = logicFns.stringify(self)
            const letters = compact(str.split(/[,|(|)]/))
            if (letters.includes(alpha)) return
          }
          let obj = logicFns.parse(logic)
          let nextKey = logicFns.nextAvailableKey(logic)
          let gadgetToAdd, newGadget2
          obj = { children: [obj] }
          if (item.alpha) {
            gadgetToAdd = removeAlpha(obj, item.alpha)
          } else {
            gadgetToAdd = { alpha: nextKey, children: [] }
            const Gadget = Gadgets[item.type]
            const config = Gadget.initialConfig ? Gadget.initialConfig() : {}
            newGadget2 = {
              alpha: nextKey,
              type: item.type,
              label: 'Gimme a Name',
              config
            }
            if (item.type !== 'Section') {
              newGadget2.formKey = shortid.generate()
            }
            nextKey = logicFns.nextAvailableKey(`${logic},${nextKey}`)
          }
          const newGadget = addAlpha(
            obj,
            alpha,
            dir,
            gadgetToAdd,
            gadgets,
            nextKey
          )
          const thingsToRemove = cleanup(obj, gadgets)
          const newLogic = logicFns.stringify(obj.children[0])
          updateConfig(draft => {
            draft.forms[i].logic = newLogic
            if (newGadget) draft.forms[i].gadgets.push(newGadget)
            if (newGadget2) draft.forms[i].gadgets.push(newGadget2)
            draft.forms[i].gadgets = draft.forms[i].gadgets.filter(
              g => !thingsToRemove.includes(g.alpha)
            )
            draft.forms[i].template = logicFns.buildTemplate(draft.forms[i])
          })
          setTimeout(updatePreview)
        }}
      >
        {onDragStart => (
          <FormalyzerConfig
            setIsDragging={setIsDragging}
            setSelected={setSelected}
            onDragStart={onDragStart}
            logic={logic}
            gadgets={gadgets}
          />
        )}
      </DropHandler>
    </Wrapper>
  )
}

function ConfigureBox ({ value, onChange, deleteMe }) {
  if (!value) return null
  return (
    <div>
      <Formy onChange={onChange} value={value}>
        {({ Section, Row, Column, ...Fields }) => (
          <Section label={`${value.type} (${value.alpha})`}>
            {value.type !== 'Section' && (
              <Fields.Text formKey='formKey' label='Form Key' />
            )}
            <Fields.Text formKey='label' label='Label' />
            {value.type !== 'Section' && (
              <Fields.Checkbox formKey='required' label='Required' />
            )}
            {value.type === 'Text' && (
              <Fields.Dropdown
                formKey='config.inputType'
                options={[
                  { id: '', label: 'none' },
                  { id: 'email', label: 'email' },
                  { id: 'number', label: 'number' },
                  { id: 'range', label: 'range' },
                  { id: 'tel', label: 'tel' },
                  { id: 'text', label: 'text' }
                ]}
              />
            )}
            {value.type === 'Date' && (
              <Fields.Dropdown
                formKey='config.filterKey'
                options={[
                  { id: '', label: 'none' },
                  { id: 'everyOtherWednesday', label: 'Every Other Wednesday' }
                ]}
              />
            )}
            {value.type === 'HelpText' && (
              <Fields.Textarea formKey='config.helpText' />
            )}
          </Section>
        )}
      </Formy>
      <Button red onClick={deleteMe}>
        Delete
      </Button>
    </div>
  )
}

function findParent (obj, alpha, parent) {
  if (obj.alpha === alpha) return parent
  let res
  for (let i = 0; i < obj.children.length; ++i) {
    res = findParent(obj.children[i], alpha, obj)
    if (res) return res
  }
}

function findSelf (obj, alpha) {
  if (obj.alpha === alpha) return obj
  let res
  for (let i = 0; i < obj.children.length; ++i) {
    res = findSelf(obj.children[i], alpha, obj)
    if (res) return res
  }
}

function removeAlpha (obj, alpha) {
  const parent = findParent(obj, alpha, null)
  const i = findIndex(parent.children, { alpha })
  const foo = parent.children.splice(i, 1)
  return foo[0]
}

function addAlpha (obj, alpha, dir, newChild, gadgets, nextAlpha) {
  const parent = findParent(obj, alpha, null)
  const type = (find(gadgets, { alpha: parent.alpha }) || {}).type
  if (dir === 'initial') {
    const self = findSelf(obj, alpha)
    self.children.push(newChild)
  }
  if (['top', 'down'].includes(dir)) {
    const modifier = dir === 'down' ? 1 : 0
    const i = findIndex(parent.children, { alpha })
    if (type === 'Column') {
      parent.children.splice(i + modifier, 0, newChild)
    } else {
      parent.children[i] = {
        alpha: nextAlpha,
        children: [parent.children[i]]
      }
      parent.children[i].children.splice(modifier, 0, newChild)
      return { alpha: nextAlpha, type: 'Column' }
    }
  }
  if (['left', 'right'].includes(dir)) {
    const modifier = dir === 'right' ? 1 : 0
    const i = findIndex(parent.children, { alpha })
    if (type === 'Row') {
      parent.children.splice(i + modifier, 0, newChild)
    } else {
      parent.children[i] = {
        alpha: nextAlpha,
        children: [parent.children[i]]
      }
      parent.children[i].children.splice(modifier, 0, newChild)
      return { alpha: nextAlpha, type: 'Row' }
    }
  }
}

function cleanup (obj, gadgets, parent, i) {
  // TODO:: also consolidate columns in columns and rows in rows
  let res = []
  for (let i = obj.children.length - 1; i >= 0; --i) {
    const removed = cleanup(obj.children[i], gadgets, obj, i)
    res.push(...removed)
  }
  const self = find(gadgets, { alpha: obj.alpha })
  if (self && ['Row', 'Column'].includes(self.type)) {
    if (!obj.children.length) {
      res.push(obj.alpha)
      parent.children.splice(i, 1)
    } else if (obj.children.length === 1) {
      res.push(obj.alpha)
      parent.children.splice(i, 1, obj.children[0])
    }
  }
  return res
}
