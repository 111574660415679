import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 49px);
  font-size: 100px;
`

export default () => (
  <Wrapper>
    <div>Uh Oh!</div>
    <div>Not Found</div>
  </Wrapper>
)
